import React from "react";
import theme from "theme";
import { Theme, Text, Section, Box, Image } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override, StackItem, Stack } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				The Gentlemen’s Den Barbershop
			</title>
			<meta name={"description"} content={"Де традиція зустрічається з сучасним стилем"} />
			<meta property={"og:title"} content={"The Gentlemen’s Den Barbershop"} />
			<meta property={"og:description"} content={"Де традиція зустрічається з сучасним стилем"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/66b9c1e8e778710018d28737/images/1-2%20%281%29.jpg?v=2024-08-12T09:42:13.462Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/66b9c1e8e778710018d28737/images/1-2%20%281%29.jpg?v=2024-08-12T09:42:13.462Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/66b9c1e8e778710018d28737/images/1-2%20%281%29.jpg?v=2024-08-12T09:42:13.462Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/66b9c1e8e778710018d28737/images/1-2%20%281%29.jpg?v=2024-08-12T09:42:13.462Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/66b9c1e8e778710018d28737/images/1-2%20%281%29.jpg?v=2024-08-12T09:42:13.462Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/66b9c1e8e778710018d28737/images/1-2%20%281%29.jpg?v=2024-08-12T09:42:13.462Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/66b9c1e8e778710018d28737/images/1-2%20%281%29.jpg?v=2024-08-12T09:42:13.462Z"} />
			<meta name={"msapplication-TileColor"} content={"#232323"} />
		</Helmet>
		<Components.Header>
			<Override slot="text" />
			<Override slot="link" />
			<Override slot="link1" />
			<Override slot="link2" />
		</Components.Header>
		<Section
			background="linear-gradient(0deg,rgba(4, 8, 12, 0.6) 0%,rgba(4, 8, 12, 0.6) 100%),--color-darkL2 url(https://uploads.quarkly.io/66b9c1e8e778710018d28737/images/1-3.jpg?v=2024-08-12T09:42:13.458Z) bottom/cover"
			padding="120px 0 120px 0"
			sm-padding="40px 0"
			color="--light"
			font="--base"
		>
			<Stack>
				<StackItem width="75%" lg-width="100%">
					<Override slot="StackItemContent" flex-direction="column" />
					<Text color="--lightD2" letter-spacing="1px" text-transform="uppercase" margin="0">
						Ласкаво просимо до "The Gentlemen’s Den"
					</Text>
					<Text as="h1" font="--headline2" md-font="--headline2" margin="10px 0">
						Де традиція зустрічається з сучасним стилем
					</Text>
				</StackItem>
			</Stack>
		</Section>
		<Section padding="60px 0 60px 0" md-padding="30px 0 30px 0" border-color="--color-secondary">
			<Override slot="SectionContent" flex-direction="row" md-flex-wrap="wrap" />
			<Box
				empty-min-width="64px"
				padding="0px 90px 0px 0px"
				md-padding="0px 0px 0px 0px"
				md-width="100%"
				width="50%"
				lg-padding="0px 28px 0px 0px"
				empty-min-height="64px"
				empty-border-width="1px"
				empty-border-style="solid"
				empty-border-color="LightGray"
				align-self="center"
			>
				<Text
					font="--headline5"
					lg-font="normal 500 32px/1.2 --fontFamily-googleAlegreyaSans, sans-serif"
					md-font="normal 500 28px/1.2 --fontFamily-googleAlegreyaSans, sans-serif"
					margin="0px 0px 32px 0px"
					color="--light"
				>
					Відчуйте преміальний догляд за собою в барбершопі "The Gentlemen’s Den". Класичні стрижки, сучасні стилі та розслаблююча атмосфера, створена для сучасного чоловіка.
				</Text>
			</Box>
			<Box
				empty-border-width="1px"
				empty-border-style="solid"
				empty-border-color="LightGray"
				width="50%"
				md-width="100%"
				empty-min-width="64px"
				empty-min-height="64px"
			>
				<Image
					src="https://uploads.quarkly.io/66b9c1e8e778710018d28737/images/1-2%20%281%29.jpg?v=2024-08-12T09:42:13.462Z"
					width="100%"
					max-height="100%"
					object-fit="cover"
					lg-min-height="300px"
					md-max-height="277px"
					md-min-height="16px"
					height="300px"
					object-position="bottom"
					srcSet="https://smartuploads.quarkly.io/66b9c1e8e778710018d28737/images/1-2%20%281%29.jpg?v=2024-08-12T09%3A42%3A13.462Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/66b9c1e8e778710018d28737/images/1-2%20%281%29.jpg?v=2024-08-12T09%3A42%3A13.462Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/66b9c1e8e778710018d28737/images/1-2%20%281%29.jpg?v=2024-08-12T09%3A42%3A13.462Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/66b9c1e8e778710018d28737/images/1-2%20%281%29.jpg?v=2024-08-12T09%3A42%3A13.462Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/66b9c1e8e778710018d28737/images/1-2%20%281%29.jpg?v=2024-08-12T09%3A42%3A13.462Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/66b9c1e8e778710018d28737/images/1-2%20%281%29.jpg?v=2024-08-12T09%3A42%3A13.462Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/66b9c1e8e778710018d28737/images/1-2%20%281%29.jpg?v=2024-08-12T09%3A42%3A13.462Z&quality=85&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
			</Box>
		</Section>
		<Section padding="36px 0 60px 0" quarkly-title="HeroBlock" md-padding="16px 0 30px 0" background="--color-darkL1">
			<Override slot="SectionContent" flex-direction="row" md-flex-wrap="wrap" />
			<Box
				empty-min-width="64px"
				empty-min-height="64px"
				empty-border-width="1px"
				empty-border-style="solid"
				empty-border-color="LightGray"
				width="30%"
				md-width="100%"
				font="--headline3"
			>
				<Text
					font="--headline5"
					color="--light"
					margin="0px 0px 0px 0px"
					lg-font="normal 700 50px/1.2 --fontFamily-googleAlegreyaSans, sans-serif"
					md-font="normal 700 40px/1.2 --fontFamily-googleAlegreyaSans, sans-serif"
					md-margin="0px 0px 24px 0px"
				>
					{" "}Традиція
					<br />
					{" "}досконалості
				</Text>
			</Box>
			<Box
				empty-border-color="LightGray"
				width="69%"
				md-width="100%"
				empty-min-width="64px"
				empty-min-height="64px"
				empty-border-width="1px"
				empty-border-style="solid"
			>
				<Text
					text-align="left"
					lg-font="normal 600 16px/1.5 &quot;Varta&quot;, sans-serif"
					lg-margin="80px 0px 0px 0px"
					md-margin="0px 0px 0px 0px"
					margin="100px 0px 0px 0px"
					color="--light"
					font="--lead"
				>
					У світі, де тренди приходять і йдуть, "The Gentlemen’s Den" залишається маяком непідвладного часу стилю та вишуканості. Наш барбершоп — це більше, ніж просто місце для стрижки, це святилище, де традиція зустрічається з сучасними методами догляду. Коли ви заходите до нас, вас зустрічає багатий аромат якісних продуктів для догляду, звук ножиць, що формують ваш ідеальний вигляд, та тепла, запрошувальна атмосфера, яка робить "The Gentlemen’s Den" на рівень вище за інші. Ми поєднуємо мистецтво барберства з сучасними трендами, гарантуючи, що кожен клієнт залишить наш салон з почуттям впевненості та найкращим виглядом.
				</Text>
			</Box>
		</Section>
		<Section padding="40px 0 0 0">
			<Text margin="0px 0px 24px 0px" color="#ffffff" font="--headline2" sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif">
				Чому обирають "The Gentlemen’s Den"?
			</Text>
		</Section>
		<Section padding="0px 0 40px 0" md-padding="20px 0 12px 0">
			<Override slot="SectionContent" flex-direction="row" md-flex-wrap="wrap" />
			<Box
				empty-border-color="LightGray"
				padding="0px 20px 0px 0px"
				md-padding="0px 0px 0px 0px"
				md-width="100%"
				empty-min-width="64px"
				empty-border-style="solid"
				width="50%"
				empty-min-height="64px"
				empty-border-width="1px"
			>
				<Text
					sm-margin="24px 0px 16px 0px"
					margin="32px 0px 16px 0px"
					font="--lead"
					color="--green"
					lg-font="normal 600 20px/1.5 &quot;Varta&quot;, sans-serif"
					md-margin="12px 0px 16px 0px"
				>
					Експертні Барбери
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					font="--base"
					color="--light"
					text-align="left"
					lg-font="normal 400 16px/1.5 &quot;Varta&quot;, sans-serif"
					md-margin="0px 0px 32px 0px"
				>
					Наша команда складається з досвідчених барберів з багаторічним досвідом, кожен з яких присвячує себе майстерності своєї справи та постійно оновлює знання про останні тенденції в догляді.
				</Text>
			</Box>
			<Box
				empty-border-color="LightGray"
				padding="0px 20px 0px 0px"
				md-padding="0px 0px 0px 0px"
				md-width="100%"
				empty-min-width="64px"
				empty-border-style="solid"
				width="50%"
				empty-min-height="64px"
				empty-border-width="1px"
			>
				<Text
					sm-margin="24px 0px 16px 0px"
					margin="32px 0px 16px 0px"
					font="--lead"
					color="--green"
					lg-font="normal 600 20px/1.5 &quot;Varta&quot;, sans-serif"
					md-margin="12px 0px 16px 0px"
				>
					Якісні Продукти
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					font="--base"
					color="--light"
					text-align="left"
					lg-font="normal 400 16px/1.5 &quot;Varta&quot;, sans-serif"
					md-margin="0px 0px 32px 0px"
				>
					Ми використовуємо тільки найкращі продукти для догляду, ретельно підібрані для різних типів волосся та стилів, щоб забезпечити належний догляд за вашим волоссям та шкірою.
				</Text>
			</Box>
			<Box
				empty-border-width="1px"
				empty-border-style="solid"
				width="50%"
				md-width="100%"
				md-padding="0px 0px 0px 0px"
				empty-min-width="64px"
				empty-min-height="64px"
				empty-border-color="LightGray"
				padding="0px 0px 0px 20px"
			>
				<Text
					margin="32px 0px 16px 0px"
					font="--lead"
					color="--green"
					lg-font="normal 600 20px/1.5 &quot;Varta&quot;, sans-serif"
					md-margin="12px 0px 16px 0px"
					sm-margin="24px 0px 16px 0px"
				>
					Розслаблююча Атмосфера
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					font="--base"
					color="--light"
					lg-font="normal 400 16px/1.5 &quot;Varta&quot;, sans-serif"
					text-align="left"
				>
					Наш барбершоп розроблений з урахуванням вашого комфорту. З м'якими шкіряними кріслами, класичним декором та привітною атмосферою, ви будете почувати себе розслаблено з моменту, як зайдете до нас.
				</Text>
			</Box>
			<Box
				empty-border-color="LightGray"
				padding="0px 20px 0px 0px"
				md-padding="0px 0px 0px 0px"
				md-width="100%"
				empty-min-width="64px"
				empty-border-style="solid"
				width="50%"
				empty-min-height="64px"
				empty-border-width="1px"
			>
				<Text
					sm-margin="24px 0px 16px 0px"
					margin="32px 0px 16px 0px"
					font="--lead"
					color="--green"
					lg-font="normal 600 20px/1.5 &quot;Varta&quot;, sans-serif"
					md-margin="12px 0px 16px 0px"
				>
					Персоналізований Сервіс
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					font="--base"
					color="--light"
					text-align="left"
					lg-font="normal 400 16px/1.5 &quot;Varta&quot;, sans-serif"
					md-margin="0px 0px 32px 0px"
				>
					Кожен клієнт унікальний, і кожна стрижка також повинна бути такою. Ми приділяємо час, щоб зрозуміти ваші вподобання та надати індивідуальні рекомендації, щоб досягти бажаного вигляду.
				</Text>
			</Box>
		</Section>
		<Section padding="36px 0 60px 0" quarkly-title="HeroBlock" md-padding="16px 0 30px 0" background="--color-darkL1">
			<Override slot="SectionContent" flex-direction="row" md-flex-wrap="wrap" />
			<Box
				empty-min-width="64px"
				empty-min-height="64px"
				empty-border-width="1px"
				empty-border-style="solid"
				empty-border-color="LightGray"
				width="30%"
				md-width="100%"
				font="--headline3"
			>
				<Text
					font="--headline5"
					color="--light"
					margin="0px 0px 0px 0px"
					lg-font="normal 700 50px/1.2 --fontFamily-googleAlegreyaSans, sans-serif"
					md-font="normal 700 40px/1.2 --fontFamily-googleAlegreyaSans, sans-serif"
					md-margin="0px 0px 24px 0px"
				>
					Наша{" "}
					<br />
					пристрасть
				</Text>
			</Box>
			<Box
				empty-border-color="LightGray"
				width="69%"
				md-width="100%"
				empty-min-width="64px"
				empty-min-height="64px"
				empty-border-width="1px"
				empty-border-style="solid"
			>
				<Text
					text-align="left"
					lg-font="normal 600 16px/1.5 &quot;Varta&quot;, sans-serif"
					lg-margin="80px 0px 0px 0px"
					md-margin="0px 0px 0px 0px"
					margin="100px 0px 0px 0px"
					color="--light"
					font="--lead"
				>
					Наші барбери - це митці, які пишаються своєю роботою, незалежно від того, чи це класична стрижка чи сучасний стиль. Ми підходимо до кожного зрізу з точністю та турботою, використовуючи техніки, які були вдосконалені протягом десятиліть. Від ідеального фейду до гострого підрізання бороди, наша увага до деталей гарантує, що ви залишите наш барбершоп з виглядом на всі сто і почуттям впевненості.
				</Text>
			</Box>
		</Section>
		<Section padding="40px 0 0 0">
			<Text margin="0px 0px 24px 0px" color="#ffffff" font="--headline2" sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif">
				Вшанування минулого, прийняття майбутнього
			</Text>
		</Section>
		<Section padding="0px 0 40px 0" md-padding="20px 0 12px 0">
			<Override slot="SectionContent" flex-direction="row" md-flex-wrap="wrap" />
			<Box
				empty-border-color="LightGray"
				padding="0px 20px 0px 0px"
				md-padding="0px 0px 0px 0px"
				md-width="100%"
				empty-min-width="64px"
				empty-border-style="solid"
				width="50%"
				empty-min-height="64px"
				empty-border-width="1px"
			>
				<Text
					sm-margin="24px 0px 16px 0px"
					margin="32px 0px 16px 0px"
					font="--lead"
					color="--green"
					lg-font="normal 600 20px/1.5 &quot;Varta&quot;, sans-serif"
					md-margin="12px 0px 16px 0px"
				>
					Традиційні Техніки
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					font="--base"
					color="--light"
					text-align="left"
					lg-font="normal 400 16px/1.5 &quot;Varta&quot;, sans-serif"
					md-margin="0px 0px 32px 0px"
				>
					"The Gentlemen’s Den" заснований на традиціях. Ми вшановуємо класичні техніки барберства, які передавалися з покоління в покоління, але не боїмося приймати майбутнє. Наші барбери навчаються найсучаснішим стилям та технікам, що дозволяє нам пропонувати широкий спектр послуг для будь-яких уподобань.
				</Text>
			</Box>
			<Box
				empty-border-color="LightGray"
				padding="0px 20px 0px 0px"
				md-padding="0px 0px 0px 0px"
				md-width="100%"
				empty-min-width="64px"
				empty-border-style="solid"
				width="50%"
				empty-min-height="64px"
				empty-border-width="1px"
			>
				<Text
					sm-margin="24px 0px 16px 0px"
					margin="32px 0px 16px 0px"
					font="--lead"
					color="--green"
					lg-font="normal 600 20px/1.5 &quot;Varta&quot;, sans-serif"
					md-margin="12px 0px 16px 0px"
				>
					Сучасний Підхід
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					font="--base"
					color="--light"
					text-align="left"
					lg-font="normal 400 16px/1.5 &quot;Varta&quot;, sans-serif"
					md-margin="0px 0px 32px 0px"
				>
					Незалежно від того, чи шукаєте ви традиційне гоління небезпечною бритвою чи сучасну зачіску, яка зараз у тренді, ми вас покриємо.
				</Text>
			</Box>
		</Section>
		<Section padding="36px 0 60px 0" quarkly-title="HeroBlock" md-padding="16px 0 30px 0" background="--color-darkL1">
			<Override slot="SectionContent" flex-direction="row" md-flex-wrap="wrap" />
			<Box
				empty-min-width="64px"
				empty-min-height="64px"
				empty-border-width="1px"
				empty-border-style="solid"
				empty-border-color="LightGray"
				width="30%"
				md-width="100%"
				font="--headline3"
			>
				<Text
					font="--headline5"
					color="--light"
					margin="0px 0px 0px 0px"
					lg-font="normal 700 50px/1.2 --fontFamily-googleAlegreyaSans, sans-serif"
					md-font="normal 700 40px/1.2 --fontFamily-googleAlegreyaSans, sans-serif"
					md-margin="0px 0px 24px 0px"
				>
					Повний цикл{" "}
					<br />
					догляду
				</Text>
			</Box>
			<Box
				empty-border-color="LightGray"
				width="69%"
				md-width="100%"
				empty-min-width="64px"
				empty-min-height="64px"
				empty-border-width="1px"
				empty-border-style="solid"
			>
				<Text
					text-align="left"
					lg-font="normal 600 16px/1.5 &quot;Varta&quot;, sans-serif"
					lg-margin="80px 0px 0px 0px"
					md-margin="0px 0px 0px 0px"
					margin="100px 0px 0px 0px"
					color="--light"
					font="--lead"
				>
					Догляд - це не просто стрижка, це весь досвід. У "The Gentlemen’s Den" ми пропонуємо повний спектр послуг догляду, розроблених, щоб залишити вас відчуттям свіжості та оновленості. Від гарячого гоління рушником до скульптурування бороди, наші послуги призначені для задоволення всіх аспектів чоловічого догляду. Ми віримо в те, що надаємо досвід, який виходить за межі крісла, пропонуючи поради та рекомендації щодо того, як підтримувати свій вигляд довго після того, як ви покинули наш салон.
				</Text>
			</Box>
		</Section>
		<Components.Footer>
			<Override slot="text" />
			<Override slot="text2" />
			<Override slot="text3" />
			<Override slot="text1" />
			<Override slot="box2" />
			<Override slot="box1" />
			<Override slot="link" />
			<Override slot="link1" />
			<Override slot="link2" />
			<Override slot="box3" />
			<Override slot="box" />
		</Components.Footer>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"6166a2b829a0a1001e6ca5fb"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});